import React, { useEffect } from 'react';
import AlertCircle from '../../assets/alert-circle.svg';
import BookMark from '../../assets/bookmark.svg';
import { ErrorModalContainer, ModalContainer, SuccessModalContainer } from './style';

interface ModalProps {
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ onClose, children }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
      onClose();
    }
  });

  return (
    <ModalContainer onClick={onClose}>
      <div style={{ margin: '0 auto', width: 'min-content' }} onClick={e => e.stopPropagation()}>{children}</div>
    </ModalContainer>
  );
}

interface BasicModalProps {
  showIcon?: boolean;
}

export const ErrorModal: React.FC<BasicModalProps> = ({ showIcon, children }) => {
  return (
    <ErrorModalContainer>
      {showIcon ? <img src={AlertCircle} width="100px" height="100px" alt='alert' /> : <></>}
      {children}
    </ErrorModalContainer>
  );
};

export const SuccessModal: React.FC<BasicModalProps> = ({ showIcon, children }) => {
  return (
    <SuccessModalContainer>
      {showIcon ? <img src={BookMark} color='white' width="100px" height="100px" alt='alert' /> : <></>}
      {children}
    </SuccessModalContainer>
  );
};

export default Modal;