import React from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import Info from './components/Info';

function App() {
  return (<>
    <Header/>
    <Info/>
    <Footer/>
  </>);
}

export default App;
