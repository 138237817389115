import React from 'react';
import { InfoContainer, InfoContent } from './style';
import PhoneShowingApp from '../../assets/phone-showing-app.png';

const Info: React.FC = () => {

  const towtsAppLink = "https://play.google.com/store/apps/details?id=br.com.towts.towts_mobile"

  return (
    <InfoContainer>
      <div><a href={towtsAppLink} target="_blank" rel="noreferrer"><img src={PhoneShowingApp} alt="iPhone"/></a></div>
      <InfoContent>
        <h2>O que somos?</h2>
        <p>Somos feitos de lembranças e haverá um dia que vamos viver apenas com elas, por qual lembrança você quer começar?</p>
        <p>Comece hoje a escrever sobre suas aventuras, como adotou seu melhor amigo ou como uma pessoa foi importante pra você. Para cada história e foto que criar e salvar, Towts vai te fornecer um código de acesso onde pode fixar no objeto que te lembra aquele episódio
          da vida.</p>
        <p>Por exemplo, no seu melhor amigo pet, pode fixar o código na coleira. Sobre o melhor show da sua vida, pode fixar na pulseira ingresso que nunca jogou fora. Para uma foto no seu quarto, pode escrever o código atrás dela. E para um ente querido pode fixar o código na lápide.</p>
        <p>As histórias são suas, são o que fazem você ser quem é. O importante é não deixar os detalhes se perderem pelo tempo. Primeiro você guarda com Towts, depois compartilha com o mundo. Vamos lá...</p>
      </InfoContent>
    </InfoContainer>
  );
}

export default Info;