import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
  }

  #root {
    background-color: #333;
  }

  .container {
    padding-left: 10%;
    padding-right: 10%;
  }
`;