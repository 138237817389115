import styled from 'styled-components';
import HeaderBG from '../../assets/bg_header.png';

export const HeaderContainer = styled.header`
  position: relative;
  background: url(${HeaderBG}) center/cover no-repeat;
  min-height: 100vh;
  
  @media screen and (min-width: 720px) {
    padding-bottom: 15%;
  }
`;

export const NavContainer = styled.nav`
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const NavBrand = styled.img`
  max-height: 100px;
`;

export const HeaderContent = styled.section`
  padding: 5%;
  @media screen and (min-width: 720px) {
    padding: 10% 10%;
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 800;
    color: white;
    margin: 0 0 2rem 0;
  }

  h3 {
    font-size: 2.0rem;
    font-weight: 400;
    color: white;
    margin: 0 0 2rem 0;
    
    @media screen and (min-width: 720px) {
      max-width: 50%;
    }
  }
`;

export const TowtsCodeInputContainer = styled.div`
  position: relative;
  display: inline-flex;
  background-color: rgba(125, 125, 125, 0.7);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
`;

export const TowtsCodeInputT = styled.span`
  display: flex;
  font-size: 1.5rem;
  font-weight: 800;
  color: white;
  background-color: rgba(125, 125, 125, 0.7);
  padding: 8px 18px;
  align-items: center;
`;

export const TowtsCodeInputIcon = styled.span`
  display: flex;
  cursor: pointer;
  padding: 8px 18px;
  align-items: center;

  img {
    height: 25px;
  }
`;

export const StoryCodeInput = styled.input`
  padding: 18px 36px 18px 18px;
  max-width: 300px;
  outline: none;
  border: none;
  background-color: transparent;
  color: #AAA;
  font-size: .9rem;

  ::-webkit-input-placeholder {
    color: #AAA;
  }

  :-ms-input-placeholder {
    color: #AAA;
  }

  ::placeholder {
    color: #AAA;
  }
`;