import React, { ReactElement, useCallback, useRef, useState } from 'react';
import Logo from '../../assets/logo.png';
import VaadinEnter from '../../assets/vaadin_enter.svg';
import Modal, { ErrorModal } from '../Modal';
import {
  HeaderContainer,
  HeaderContent,
  NavBrand,
  NavContainer,
  StoryCodeInput,
  TowtsCodeInputContainer,
  TowtsCodeInputIcon,
  TowtsCodeInputT
} from './style';
import SuccessStoryModal from './SuccessStoryModal';

const Header: React.FC = () => {
  const towtsRef = useRef<HTMLInputElement>(null);
  const [modal, setModal] = useState<ReactElement | null>(null);

  const closeModal = useCallback(() => setModal(null), []);
  const updateModal = useCallback((el: ReactElement) => setModal(el), []);

  const requestTowts = useCallback(() => {
    const code = towtsRef.current?.value || '';
    if (code === '') {
      alert('Informe um código towts!');
      return;
    } else if (!/^[A-Z0-9]{8}$/g.test(code)) {
/*      updateModal(<ErrorModal><p>Código inválido</p></ErrorModal>);
      return;*/
    }

    fetch(`${process.env.REACT_APP_BACKEND}/story/${code}`)
      .then(response => {
        if (response.status === 404) {
          updateModal(<ErrorModal><p>Story não encontrada</p></ErrorModal>);
          return
        }

        if (response.status === 401) {
          updateModal(<ErrorModal><p>A story não é pública</p></ErrorModal>);
          return
        }

        response.json().then(json => {
          updateModal(
            <SuccessStoryModal {...json} />
          );
        })
      });
  }, [updateModal]);

  return (
    <HeaderContainer>
      <NavContainer className='container'>
        <NavBrand src={Logo} />
      </NavContainer>

      <HeaderContent>
        <h1>Guarde todos os momentos</h1>
        <h3>Towts, seu app de lembranças. Cada história criada um novo código para compartilhar.</h3>

        <TowtsCodeInputContainer>
          <TowtsCodeInputT>T-</TowtsCodeInputT>
          <StoryCodeInput
            ref={towtsRef}
            type='text'
            placeholder='Digite um código de uma story'
            onKeyUp={e => {
              if (e.key === 'Enter') requestTowts();
            }}
            maxLength={8}
            required
          />
          <TowtsCodeInputIcon onClick={requestTowts}>
            <img src={VaadinEnter} alt="Enter" />
          </TowtsCodeInputIcon>
        </TowtsCodeInputContainer>
      </HeaderContent>
      {
        modal !== null ? (
          <Modal onClose={closeModal}>{modal}</Modal>
        ) : <></>
      }
    </HeaderContainer>
  );
}

export default Header;