import styled from "styled-components";

export const FooterContainer = styled.footer`
  padding: 15px 10% 15px 10%;
  text-align: center;
  background-color: white;

  h3 {
    color: #4E4E4E;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0 0 2rem 0;

    a {
      color: inherit;
      text-decoration: none;
      font-weight: 800;
    }
  }

  h4 {
    color: #242424;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
  }
`;