import React from 'react';
import { FooterContainer } from './style';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <h3>Fale conosco: <a href="mailto:contato@towts.com.br">contato@towts.com.br</a></h3>
      <h4>&copy; Todos os direitos reservados - Towts</h4>
    </FooterContainer>
  );
}

export default Footer;