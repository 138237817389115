import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

  padding: 5%;
  overflow-y: auto;

  @media screen and (min-width: 1280px) {
    padding: 5% 10%;
  }

  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
`;

const BasicModalContainer = `
  display: inline-block;
  border-radius: 8px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
  padding: 32px;
  min-width: 500px;
  text-align: center;

  p {
    color: white;
    margin: 10px 0 0 0;
    font-size: 1.2rem;
    font-weight: 800;

    :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  span {
    color: white;
    font-size: 1rem;
    font-weight: 400;

    :not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

export const ErrorModalContainer = styled.div`
  ${BasicModalContainer};
  background-color: #e74c3c;
`;

export const SuccessModalContainer = styled.div`
  ${BasicModalContainer};
  background-color: #723e36;
`;