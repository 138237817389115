import styled from 'styled-components';
import InfoBottom from '../../assets/info_bottom.svg';

export const InfoContainer = styled.section`
  position: relative;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5% 15% 12%;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    padding-bottom: 50%;
  }

  @media screen and (min-width: 720px) and (max-width: 1444px) {
    padding-bottom: 25%;
    
    img {
      max-width: 80% !important;
    }
  }

  @media screen and (min-width: 1444px) {
    padding-bottom: 10%;
  }

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: url(${InfoBottom}) bottom center/cover;
  }

  > div:first-child {
    flex-basis: 50%;
    text-align: center;

    @media screen and (max-width: 720px) {
      margin-bottom: 15px;
      
      img {
        max-width: 70%;
      }
    }

    img {
      margin: 5%;
      max-width: 50%;
    }
  }
`;

export const InfoContent = styled.div`
  text-align: center;
  flex-basis: 50%;
  
  h2 {
    position: relative;
    font-weight: 900;
    color: white;
    margin: 0 0 3rem 0;
    
    @media screen and (max-width: 720px) {
      font-size: 2rem;
    }
    
    @media screen and (min-width: 720px) {
      font-size: 3rem;
    }

    ::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 20%;
      width: 60%;
      height: 4px;
      background-color: white;
    }
  }

  p {
    text-align: left;
    color: white;
    font-weight: 400;
    margin: 0;

    @media screen and (max-width: 720px) {
      font-size: 1rem;
    }
    
    @media screen and (min-width: 720px) {
      font-size: 1.3rem;
    }

    :not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`;
