import EyeIcon from '../../../assets/eye.svg';
import { SuccessModal } from '../../Modal';
import { ModalUserInfo, StoryPhoto, UserPhoto } from './style';
import React from "react";

interface StoryProps {
  name: string,
  message: string,
  image: string,
  createdDate: string,
  views: number,
  creatorFirstName: string,
  creatorSecondName: string,
  creatorImage: string,
  creatorUf?: String,
  creatorCity?: String
}

const SuccessStoryModal: React.FC<StoryProps> = (props) => {

  const getFormattedDate = () => {
    const options: any = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(props.createdDate).toLocaleString("pt-BR", options)
  }

  const getFullName = () => `${props.creatorFirstName} ${props.creatorSecondName}`;

  const getCityAndUf = ({ creatorCity, creatorUf } : StoryProps = props) => {
    if (creatorCity == null && creatorUf == null) {
      return "";
    }

    if (creatorCity != null && creatorUf == null) {
      return creatorCity!;
    }

    if (creatorCity == null && creatorUf != null) {
      return creatorUf!;
    }

    return `${creatorCity} - ${creatorUf}`;
  }

  return (
    <SuccessModal showIcon={false}>
      <ModalUserInfo>
        <UserPhoto url={process.env.REACT_APP_IMAGES + "/user/" + props.creatorImage + ".jpg"} />
        <p><strong>{getFullName()}</strong></p>
        <span><strong>{getCityAndUf()}</strong></span>
      </ModalUserInfo>
      <div>
        <p>{props.name}</p>
        <span>{props.message}</span>
        <StoryPhoto src={process.env.REACT_APP_IMAGES + "/story/" + props.image + ".jpg"} alt='storyImage'/>
        <span>Criado em {getFormattedDate()}</span>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '5px' }}>
          <img src={EyeIcon} color='white' alt="eye" width='15px' height='15px' />
          <span style={{ marginLeft: '7px' }}>{props.views}</span>
        </div>
      </div>
    </SuccessModal>
  )
}

export default SuccessStoryModal;