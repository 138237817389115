import styled from "styled-components";

interface Props {
  url: string;
}

export const ModalUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5%;
`;

export const StoryPhoto = styled.img`
  display: block;
  max-width: 100%;
  margin: 10px 0;
  border-radius: 8px;
`;

export const UserPhoto = styled.div<Props>`
  display: block;
  width: 150px;
  height: 150px;
  background: url(${props => props.url}) center/cover no-repeat;
  border-radius: 50%;
  margin-bottom: 10px;
`;